import React, { Component, Fragment } from 'react';

import Aura from './Aura';
import DistanceLabel from './DistanceLabel';
import FabricButton from './FabricButton';
import Path from './Path';
import Token from './Token';

export default class Model extends Component {
  state = {}
  select() {
    const { canvas, record} = this.props;
    canvas.forEachObject(object => {
      if (object.record && object.record.path === record.path) {
        canvas.setActiveObject(object);
      }
    });
  }
  cancelMove = () => {
    const { record, clearPendingMove } = this.props;
    const { moves } = record.attrs;
    record.update({ moves: [], facing: moves[0].facing || 0, x: moves[0].x, y: moves[0].y });
    clearPendingMove(record); // Removes from game.state.movingModels
    this.select();
  }
  confirmMove = () => {
    const { record, clearPendingMove} = this.props;
    record.update({ moves: [] });
    clearPendingMove(record);
    this.select();
  }
  componentDidMount() {
    this.props.canvas.on('object:moving', this.onObjectMoving);
    this.props.canvas.on('object:moved', this.onObjectMoved);
  }
  componentWillUnmount() {
    this.props.canvas.off('object:moving', this.onObjectMoving);
    this.props.canvas.off('object:moved', this.onObjectMoved);
  }
  confirmMoveButton() {
    const { canvas, pixelsPerInch, record } = this.props;
    const { moves, width, x, y } = record.attrs;
    if (!moves || moves.length === 0) return null;
    const lastMove = moves[moves.length - 1];
    if (lastMove.x !== x || lastMove.y !== y) return null;

    const left = pixelsPerInch * x;
    const top = pixelsPerInch * y;
    const buttonWidth = pixelsPerInch;
    return (
      <Fragment>
        <FabricButton
          canvas={canvas}
          onClick={this.confirmMove}
          text="✓"
          left={left - buttonWidth * 3 / 4 - pixelsPerInch * width / 2}
          top={top}
          width={buttonWidth}
          height={buttonWidth}
          color={'#4caf50'}
        />
        <FabricButton
          canvas={canvas}
          onClick={this.cancelMove}
          text="✗"
          left={left + buttonWidth * 3 / 4 + pixelsPerInch * width / 2}
          top={top}
          width={buttonWidth}
          height={buttonWidth}
          color={'#f44336'}
        />
      </Fragment>
    );
  }
  token() {
    let tokenAttrs = this.props.record.attrs;
    const meleeRanges = (tokenAttrs.attacks || []).map(attack => attack.type === 'Melee' && attack.range);
    const meleeRange = tokenAttrs.maxMeleeRange || Math.max(0, ...meleeRanges);
    const selections = this.props.selections;
    return (
      <Token
        record={this.props.record}
        selections={selections}
        selectable
        meleeRange={meleeRange}
        opacity={1}
        canvas={this.props.canvas}
        x={this.state.x || tokenAttrs.x}
        y={this.state.y || tokenAttrs.y}
        immobile={tokenAttrs.immobile}
        color={tokenAttrs.color}
        label={tokenAttrs.label}
        imageUrl={tokenAttrs.imageUrl}
        width={tokenAttrs.width}
        height={tokenAttrs.height || tokenAttrs.width}
        shape={tokenAttrs.shape}
        pixelsPerInch={this.props.pixelsPerInch}
        facing={this.state.facing || tokenAttrs.facing}
        activated={tokenAttrs.activated}
        isActivating={tokenAttrs.isActivating}
        conditions={tokenAttrs.conditions}
        showArcs={this.props.showArcs && !tokenAttrs.immobile}
        showDistance={this.props.showDistance}
        resource1={tokenAttrs.resource1}
        resource2={tokenAttrs.resource2}
        resource3={tokenAttrs.resource3}
        tint={tokenAttrs.tint}
        effects={tokenAttrs.effects}
        isDragging={this.state.x}
      />
    )
  }
  onObjectMoving = ({ target }) => {
    const { record } = this.props;
    let object = null;
    if (target.type === 'activeSelection') {
      object = target.getObjects().find(o => o.record && o.record.path === record.path);
    } else if (target.record && target.record.path === record.path) {
      object = target;
    }
    if (object) {
      let left = object.group ? object.group.left + object.left + object.group.width / 2 : object.left;
      let top = object.group ? object.group.top + object.top + object.group.height / 2 : object.top;
      let x = left / this.props.pixelsPerInch;
      let y = top / this.props.pixelsPerInch;
      this.setState({
        x,
        y,
        facing: object.angle || 0,
      });
    }

  }
  onObjectMoved = () => {
    this.setState({
      x: null,
      y: null,
      facing: null,
    });
  }
  path() {
    const { canvas, pixelsPerInch, record } = this.props;
    const { width } = record.attrs;
    const moves = this.moves();
    if (!moves || !moves.length) return null;
    return (
      <Path
        canvas={canvas}
        pixelsPerInch={pixelsPerInch}
        waypoints={moves}
        width={width}
        onRevert={(moves) => {
          const lastMove = moves[moves.length - 1];
          if (moves.length === 1) {
            moves = [];
          }
          record.update({ x: lastMove.x, y: lastMove.y, moves });
          this.select();
        }}
      />
    )
  }
  moves() {
    const { record } = this.props;
    const { moves } = record.attrs;
    if (!moves) return;
    return [
      ...moves,
      {
        x: this.state.x || record.attrs.x,
        y: this.state.y || record.attrs.y,
        doubleCost: record.attrs.doubleMoveCost || false
      }
    ];
  }
  distanceLabel() {
    const { canvas, pixelsPerInch, record } = this.props;
    const moves = this.moves();
    if (!moves || moves.length === 0) return null;
    return (
      <DistanceLabel
        canvas={canvas}
        pixelsPerInch={pixelsPerInch}
        record={record}
        moves={moves}
      />
    )
  }
  aura() {
    const { canvas, pixelsPerInch, record, selections } = this.props;
    if (!record.attrs.auraRadius) return null;
    return (
      <Aura
        canvas={canvas}
        record={record}
        shape={this.props.record.attrs.shape}
        isSelected={selections.map(selection => selection.id).includes(record.id)}
        pixelsPerInch={pixelsPerInch}
      />
    )
  }
  render() {
    if (this.props.editMapMode && !this.props.record.attrs.immobile) return null;

    return (
      <Fragment>
        {this.aura()}
        {this.distanceLabel()}
        {this.path()}
        {this.token()}
        {this.confirmMoveButton()}
      </Fragment>
    );
  }
}
