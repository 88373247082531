import React, { Component, Fragment } from 'react';

import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import ActionButton from '../../../game/ActionButton';

export default class extends Component {
  render() {
    return (
      <ActionButton
        icon={Grid4x4Icon}
        primary="Deployment Zones"
        onClick={() => this.props.game.update({ deploy: true })}
      />
    );
  }
}
