import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import withWidth, { isWidthDown, isWidthUp } from '../withWidth';
import { fabric } from 'fabric-with-gestures';
import ReactGA from 'react-ga4';

import { connectToGame, resetGame } from '../initFirebase';
import { loadData, loadSettings, loadextensions } from '../modules';
import ActionDrawer from './ActionDrawer';
import AddModelDialog from './AddModelDialog';
import Header from './Header';
import AddWidgetDialog from './AddWidgetDialog';
import AddShapeDialog from './AddShapeDialog';
import CardsDialog from './CardsDialog';
import ClockDialog from './clock/ClockDialog';
import CommandDialog from './CommandDialog';
import EditDialog from './EditDialog';
import InviteDialog from './InviteDialog';
import LogDialog from './LogDialog';
import LogSnackbar from './LogSnackbar';
import NotesDialog from './NotesDialog';
import RollDialog from './RollDialog';
import ConfirmAllButton from './ConfirmAllButton';
import SaveMapDialog from './SaveMapDialog';
import SearchDeckDialog from './SearchDeckDialog';
import SelectionDrawer from './SelectionDrawer';
import KeyboardShortcutsDialog from './KeyboardShortcutsDialog';

import SelectionActions from './SelectionActions';
import SelectionTooltip from './SelectionTooltip';
import Map from '../map/Map';
import { randomColor } from './ColorPicker';
import toggleActivePlayer from './clock/toggleActivePlayer';
import togglePause from './clock/togglePause';

class Game extends Component {
  state = {
    modal: null,
    addModelOpen: false,
    addShapeOpen: false,
    addWidgetOpen: false,
    // canvasSize: Math.max(window.innerWidth, window.innerHeight, 1024),
    clockOpen: false,
    commandDialogOpen: false,
    editOpen: false,
    editMapMode: false,
    exit: false,
    inviteOpen: false,
    keyboardShortcutsOpen: false,
    logOpen: false,
    game: undefined,
    notesOpen: false,
    rollOpen: false,
    saveMapOpen: false,
    searchDeckOpen: false,
    selectedColor: (window.localStorage && window.localStorage.getItem('selectedColor')) || randomColor(),
    selection: undefined,
    selections: [],
    showCards: false,
    movingModels: []
  }
  canvas = new fabric.Canvas();
  constructor(props) {
    super(props);
    let connection = connectToGame(props.match.params.id);
    this.state.connection = connection;
    this.state.actionDrawerOpen = isWidthUp('md', this.props.width);
    this.checkClock();
  }
  componentDidMount() {
    this.state.connection.onChange(this.gameUpdated);
    document.addEventListener('keydown', this.keydown);
    document.addEventListener('keyup', this.keyup);
    document.addEventListener('click', this.click);
  }
  componentWillUnmount() {
    if (this.canvas) {
      this.canvas.clear();
      this.canvas.dispose();
    }
    document.removeEventListener('keydown', this.keydown);
    document.removeEventListener('keyup', this.keyup);
    document.removeEventListener('click', this.click);
  }
  checkClock = () => {
    if (!('fetch' in window)) return;

    fetch('/', { method: 'HEAD' }).then(res => {
      const serverDate = new Date(res.headers.get('Date'));
      const localDate = new Date();
      const diff = serverDate.getTime() - localDate.getTime();

      if (Math.abs(diff) > 10000) {
        const diffSeconds = Math.round(Math.abs(diff) / 1000);
        alert(`Your computer’s clock is ${Math.abs(diffSeconds)} seconds ${diff > 0 ? 'behind' : 'ahead'}. Please consider using your system’s "Set date/time automatically" option to correct this issue. Incorrect clocks can interfere with War Table, even if you’re just observing!`);
      }
    });
  }
  keyup = (e) => {
    if (['Slash', 'NumpadDivide'].includes(e.code)) {
      if (e.shiftKey) {
        this.setState({ keyboardShortcutsOpen: true });
        ReactGA.event({
          category: 'Keyboard Shortcut',
          action: 'Keyboard Shortcuts Dialog'
        });
      } else {
        this.setState({ commandDialogOpen: true });
        ReactGA.event({
          category: 'Keyboard Shortcut',
          action: 'Command Dialog'
        });
        return false;
      }
    }
  }
  click = (e) => {
    // work around "enter" and "spacebar" keys trigger click on last active element
    if (document.activeElement && !['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName)) {
      document.activeElement.blur();
    }
    document.body.focus()
  }
  keydown = (e) => {
    if ((document.activeElement && ['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName)) || document.querySelector('[role="dialog"]')) {
      return;
    }
    const { connection, game, selection, selections } = this.state;
    if (e.code === 'Backspace' || e.code === 'Delete') {
      this.onRemove();
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: 'Remove Model'
      });
    }
    if (e.code === 'KeyC' && (e.ctrlKey || e.metaKey) && selection && selection.type === 'token' && selection.attrs.deletable) {
      this.onCopyToken();
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: 'Copy Model'
      });
    }
    if (e.code === 'Space' && game.attrs.clockEnabled) {
      if (document.activeElement) document.activeElement.blur();
      if (e.shiftKey || game.attrs.clockPaused) {
        togglePause(game, this.addLog)
        ReactGA.event({
          category: 'Keyboard Shortcut',
          action: 'Pause Clock'
        });
      } else {
        toggleActivePlayer(game, this.addLog);
        ReactGA.event({
          category: 'Keyboard Shortcut',
          action: 'Switch Clock'
        });
      }
    }
    if (['Enter', 'NumpadEnter'].includes(e.code)) {
      if (document.activeElement) document.activeElement.blur();
      if (e.shiftKey) {
        this.confirmMoves();
        ReactGA.event({
          category: 'Keyboard Shortcut',
          action: 'Confirm All Moves'
        });
      } else if (selections.length) {
        this.confirmMovesForSelected(selections);
        ReactGA.event({
          category: 'Keyboard Shortcut',
          action: 'Confirm Move'
        });
      }
    }
    if (['BracketLeft'].includes(e.code) && selection && selection.type === 'token') {
      if (e.metaKey || e.ctrlKey) return;
      if (!selection) return;
      let resource = 'resource2';
      if (e.shiftKey) {
        resource = 'resource3';
      }
      const resourceLabel = selection.attrs[`${resource}Label`] || game.attrs[`${resource}Label`]
      const currentAmount = selection.attrs[resource];
      if (!currentAmount) return;
      const afterAmount = currentAmount - 1;
      this.addLog(`${selection.attrs.label} lost 1 ${resourceLabel} (at ${afterAmount})`)
      selection.update({ [resource]: afterAmount })
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: `Decrement Resource ${e.shiftKey ? 3 : 2}`
      });
    }
    if (['BracketRight'].includes(e.code) && selection && selection.type === 'token') {
      if (e.metaKey || e.ctrlKey) return;
      if (!selection) return;
      let resource = 'resource2';
      if (e.shiftKey) {
        resource = 'resource3';
      }
      const resourceLabel = selection.attrs[`${resource}Label`] || game.attrs[`${resource}Label`]
      const currentAmount = selection.attrs[resource] || 0;
      const afterAmount = currentAmount + 1;
      this.addLog(`${selection.attrs.label} gained 1 ${resourceLabel} (at ${afterAmount})`)
      selection.update({ [resource]: afterAmount })
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: `Increment Resource ${e.shiftKey ? 3 : 2}`
      });
    }
    if (['Minus', 'NumpadSubtract'].includes(e.code)) {
      if (e.metaKey) return;
      if (e.shiftKey) {
        this.zoom(-0.05);
        return;
      }
      if (!selection) return;
      if (selection.type === 'widget' && selection.attrs.widgetType === 'Counter') {
        const { value } = selection.attrs;
        const newValue = Math.max(value - 1, 0);
        selection.update({ value: newValue });
        this.addLog(`${selection.attrs.label} counter decreased to ${newValue}`);
      }
      if (selection.type !== 'token') return;
      let resource = 'resource1';
      const currentAmount = selection.attrs[resource] || 0;
      if (!currentAmount) return;
      const resourceLabel = selection.attrs[`${resource}Label`] || game.attrs[`${resource}Label`]
      let diff = 1;
      let diffInput = window.prompt(`How many ${resourceLabel}? (blank for 1)`);
      if (diffInput === null) return;
      if (diffInput && parseInt(diffInput, 10)) {
        diff = Math.abs(parseInt(diffInput, 10));
      }
      if (diff > currentAmount) diff = currentAmount;
      const afterAmount = currentAmount - diff;
      this.addLog(`${selection.attrs.label} lost ${diff} ${resourceLabel} (at ${afterAmount})`)
      if (this.state.extensions.applyDamage) {
        this.state.extensions.applyDamage(selection, resource, diff);
      } else {
        selection.update({ [resource]: afterAmount })
      }
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: `Decrease Health`
      });
    }
    if (['Equal', 'NumpadPlus'].includes(e.code)) {
      if (e.metaKey || e.ctrlKey) return;
      if (e.shiftKey) {
        this.zoom(0.05);
        return;
      }
      if (!selection) return;
      if (selection.type === 'widget' && selection.attrs.widgetType === 'Counter') {
        const { value } = selection.attrs;
        const newValue = value + 1;
        selection.update({ value: newValue });
        this.addLog(`${selection.attrs.label} counter increased to ${newValue}`);
      }
      if (selection.type !== 'token') return;
      let resource = 'resource1';
      const resourceLabel = selection.attrs[`${resource}Label`] || game.attrs[`${resource}Label`]
      let diff;
      if (selection.attrs.damageGrid) return;
      let diffInput = window.prompt(`How many ${resourceLabel}? (blank for 1)`);
      if (diffInput === null) return;
      if (diffInput && parseInt(diffInput, 10)) {
        diff = parseInt(diffInput, 10);
      }
      diff = diff || 1;
      const afterAmount = Math.max(0, (selection.attrs[resource] || 0) + diff);
      this.addLog(`${selection.attrs.label} gained ${diff} ${resourceLabel} (at ${afterAmount})`)
      selection.update({ [resource]: afterAmount })
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: `Increase Health`
      });
    }
    let effectColor, effect;
    if (e.code === 'KeyR') {
      effect = 'R';
      effectColor = "#f44336"
    } else if (e.code === 'KeyG') {
      effect = 'G';
      effectColor = "#4caf50"
    } else if (e.code === 'KeyB') {
      effect = 'B';
      effectColor = '#2196f3';
    }
    if (effect) {
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: `Toggle Effect`
      });
      selections.forEach(model => {
        if (model.type === 'token') {
          let newEffects = [...(model.attrs.effects || [])];
          const index = newEffects.findIndex(e => e.text === effect);
          if (index >= 0) {
            newEffects.splice(index, 1);
          } else {
            newEffects.push({
              text: effect,
              color: effectColor
            });
          }
          model.update({ effects: newEffects });
        }
      });
    }
  }
  exit = () => {
    this.setState({ exit: true });
  }
  gameUpdated = async (game) => {
    let extensions = this.state.extensions;
    if (!extensions) {
      extensions = await loadextensions(game.attrs.system);
    }
    let moduleData = this.state.moduleData;
    if (!moduleData) {
      moduleData = await loadData(game.attrs.system);
    }
    this.setState({ extensions, game, moduleData });
  }
  changeSelection = (records) => {
    if (records && records.length === 1) {
      this.setState({ selection: records[0], selections: records });
    } else {
      if (this.state.selection && this.state.selection.attrs.doubleMoveCost) {
        this.state.selection.update({ doubleMoveCost: false });
      }
      this.setState({ selection: null, selections: records || [] });
    }
  }
  onModalOpen = (modal) => {
    this.setState({modal});
  }
  onModalClose = () => {
    this.setState({modal: null});
  }
  onChangeColor = (selectedColor) => {
    this.setState({ selectedColor })
    window.localStorage && window.localStorage.setItem('selectedColor', selectedColor);
  }
  onAddToken() {
    if (this.state.moduleData.models?.length) {
      this.setState({ addModelOpen: true });
    } else {
      this.selectNewToken(true);
      this.addToken({
        x: 24,
        y: 24,
        deletable: true,
      })
    }
  }
  addToken = (attrs) => {
    this.state.connection.addToken(attrs);
  }
  selectNewToken(edit) {
    const select = (event) => {
      let { target } = event;
      if (target.record) {
        this.canvas.setActiveObject(target);
        this.canvas.off('object:added', select);
        if (edit) {
          this.setState({ editOpen: true });
        }
      }
    };
    this.canvas.on('object:added', select);
  }
  onCopyToken() {
    this.selectNewToken();
    let { effects, x, width } = this.state.selection.attrs;
    if (this.state.game.attrs.deploy) {
      x = x + width + 0.01;
    }
    effects = [...(effects || [])];
    for (let i = 0; i < effects.length; i++) {
      const effect = { ...effects[i] }
      const copyNum = parseInt(effect.text.replace('#', ''), 10);
      if (`#${copyNum}` === effect.text) {
        effect.text = `#${copyNum + 1}`;
      }
      effects[i] = effect;
    }
    this.state.connection.add('token', { ...this.state.selection.attrs, effects, x });
  }
  onAddWidget(widgetType, widgetAttrs) {
    const { selection } = this.state;
    let attrs = {
      widgetType,
      x: this.state.game.attrs.width / 2,
      y: this.state.game.attrs.height / 2,
      facing: 0,
      deletable: true,
      ...widgetAttrs,
    };
    if (selection && (!widgetAttrs || !widgetAttrs.x)) {
      attrs.x = selection.attrs.x;
      attrs.y = selection.attrs.y;
      if (attrs.y < this.state.game.attrs.height / 2 && widgetType.includes('Ruler')) {
        attrs.facing = 180;
      }
    }
    if (selection && selection.attrs.width && attrs.modelOffset) {
      attrs.rotateRadius = selection.attrs.width / 2;
    }
    this.selectNewToken();
    this.state.connection.add('widget', attrs);
  }
  // pixelsPerInch() {
  //   return this.state.canvasSize / this.state.game.attrs.height;
  // }
  zoom = (diff) => {
    this.zoomToPoint({ x: window.innerWidth / 2, y: window.innerHeight / 2 }, diff);
  }
  zoomToPoint = ({ x, y }, diff) => {
    let zoom = this.canvas.getZoom();
    zoom = zoom + diff * zoom;
    if (zoom > 10) zoom = 10;
    if (zoom < 0.2) zoom = 0.2;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.set({
        cornerSize: 25 * this.canvas.getZoom(),
        rotatingPointOffset: 30 * this.canvas.getZoom(),
      });
    }
    this.canvas.zoomToPoint({ x, y }, zoom);
  }
  addLog = (text, secondary) => {
    const attrs = {
      createdAt: new Date().getTime(),
      text
    };
    if (secondary) attrs.secondary = secondary;
    this.state.connection.add('log', attrs);
  }
  resetGame = async () => {
    const { game } = this.state;

    const { clockEnabled, inviteCode, player1Color, player2Color, player1Label, player2Label, system } = game.attrs;
    const { gameSettings } = await loadSettings(game.attrs.system);
    await resetGame(this.state.connection, { ...(gameSettings || {}), inviteCode, player1Label, player2Label, clockEnabled: clockEnabled || false });

    const { setup } = this.state.extensions;
    if (setup) {
      await setup(this.state.connection);
    }
  }
  onRemove = () => {
    const { selections } = this.state;
    if (selections.length > 1 && !window.confirm("Are you sure you want to remove these models?")) {
      return;
    }
    selections.forEach(selection => {
      if (!selection.attrs.deletable) return;
      if (selections.length === 1 && selection.attrs.resource1 && !window.confirm("Are you sure you want to remove this model?")) {
        return;
      }
      this.clearPendingMove(selection);
      selection.remove();
    })
  }
  confirmMoves = () => {
    this.state.connection.listTokens().then(tokens => {
      tokens.forEach(token => {
        const { moves } = token.attrs;
        if (moves && moves.length > 0) {
          this.confirmMove(token);
        }
      });
    })
    this.state.movingModels = [];
  }
  confirmMovesForSelected = (selections) => {
    selections.forEach(selection => {
      this.confirmMove(selection);
    });
  }
  confirmMove = (record) => {
      record.update({ moves: [] });
      this.clearPendingMove(record);
  }
  clearPendingMove = (record) => {
      this.state.movingModels = this.state.movingModels.filter(item => item != record.id);
  }
  toggleDeploymentLines = () => {
    this.state.game.update({ deploy: !this.state.game.attrs.deploy });
  }
  searchDeck = () => {
    const { selection } = this.state;
    this.addLog(`Opened Deck "${selection.attrs.name}"`)
    this.setState({ searchDeckOpen: true });
  }
  onDraw = () => {
    const countText = window.prompt('How many cards? (blank for 1)');
    let count = parseInt(countText, 10);
    if (!count) count = 1;
    const { selection } = this.state;
    const cards = [...selection.attrs.cards];
    if (cards.length < count) count = cards.length;
    const newCards = cards.splice(0, count);
    this.selectNewToken();
    this.state.connection.add('deck', {
      label: selection.attrs.label,
      cards: newCards,
      y: selection.attrs.y,
      x: selection.attrs.x + selection.attrs.width + 0.25,
      width: selection.attrs.width,
      height: selection.attrs.height,
    }).then(() => {
      if (cards.length === 0) {
        selection.remove();
      } else {
        selection.update({ cards });
      }
      this.setState({ searchDeckOpen: true });
    });
  }
  onShuffle = () => {
    const { selection } = this.state;
    const cards = [...selection.attrs.cards];
    for (let i = cards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i);
      const temp = cards[i];
      cards[i] = cards[j];
      cards[j] = temp;
    }
    selection.update({ cards });
  }
  render() {
    let { connection, extensions, editMapMode, exit, game, moduleData, selection, selections, movingModels } = this.state;
    if (!game || !moduleData || !extensions) {
      return null;
    }
    if (exit) {
      return <Redirect push to={`/modules/${game.attrs.system}`} />
    }

    const gameRatio = game.attrs.width / game.attrs.height;
    const windowRatio = window.innerWidth / window.innerHeight;
    let mapWidth = window.innerWidth;
    let mapHeight = window.innerHeight;
    let canvasWidth = null;
    let canvasHeight = null;
    if (windowRatio > gameRatio) {
      mapHeight = mapWidth / gameRatio;
      canvasWidth = Math.max(window.innerWidth, 1024);
      canvasHeight = canvasWidth / gameRatio;
    }
    else {
      mapWidth = mapHeight * gameRatio;
      canvasHeight = Math.max(window.innerHeight, 1024);
      canvasWidth = canvasHeight * gameRatio;
    }
    const pixelsPerInch = canvasHeight / game.attrs.height;

    const selectionActions = <SelectionActions
      selection={selection}
      extensions={extensions}
      game={game}
      connection={connection}
      log={this.addLog}
      onAddWidget={this.onAddWidget.bind(this)}
      onCopyToken={this.onCopyToken.bind(this)}
      onDraw={this.onDraw}
      onEdit={() => { this.setState({ editOpen: true })}}
      onRemove={this.onRemove}
      onSearchDeck={this.searchDeck}
      onShowCards={() => { this.setState({ showCards: true })}}
      onShuffle={this.onShuffle}
      system={this.state.game.attrs.system}
      onModalOpen={this.onModalOpen}
      onModalClose={this.onModalClose}
    />
    let ConfiguredAddModelDialog = extensions.AddModelDialog;
    if (moduleData.models?.length && !ConfiguredAddModelDialog) {
      ConfiguredAddModelDialog = AddModelDialog;
    }
    const userSelect = isWidthDown('md', this.props.width) ? 'none' : undefined;
    return (
      <div style={{ height: '100vh', width: '100vw', overflow: 'hidden', userSelect, WebkitUserSelect: userSelect }}>
        <Header
          connection={connection}
          extensions={extensions}
          game={game}
          log={this.addLog}
          toggleActionDrawer={ () => { this.setState({ actionDrawerOpen: !this.state.actionDrawerOpen })}}
        />

        <ActionDrawer
          selection={selection}
          editMapMode={editMapMode}
          extensions={extensions}
          connection={connection}
          game={game}
          color={this.state.selectedColor}
          onChangeColor={this.onChangeColor}
          addLog={this.addLog}
          addWidget={this.onAddWidget.bind(this)}
          onAddModel={this.onAddToken.bind(this)}
          onAddToken={this.addToken}
          onAddWidget={() => { this.setState({ addWidgetOpen: true })}}
          onAddShape={() => { this.setState({ addShapeOpen: true })}}
          onClock={() => this.setState({ clockOpen: true })}
          onToggleDeploymentLines={this.toggleDeploymentLines}
          onEditMap={() => { this.setState({ editMapMode: !this.state.editMapMode })}}
          onExit={this.exit}
          onInvite={() => { this.setState({ inviteOpen: true })}}
          onKeyboardShortcuts={() => this.setState({ keyboardShortcutsOpen: true })}
          onLists={() => { this.state.game.update({ phase: 'lists' })}}
          onLog={() => { this.setState({ logOpen: true })}}
          onNotes={() => { this.setState({ notesOpen: true })}}
          onResetGame={this.resetGame}
          onRoll={() => { this.setState({ rollOpen: true })}}
          onSaveMap={() => { this.setState({ saveMapOpen: true })}}
          onZoomIn={() => { this.zoom(0.05) }}
          onZoomOut={() => { this.zoom(-0.05) }}
          onClose={() => { this.setState({ actionDrawerOpen: false })}}
          open={this.state.actionDrawerOpen}
          user={this.props.user}
          system={this.state.game.attrs.system}
          deploy={this.state.game.attrs.deploy}
        />

        <div>
          <Map
            connection={connection}
            canvas={this.canvas}
            extensions={extensions}
            game={game}
            editMapMode={editMapMode}
            pixelsPerInch={pixelsPerInch}
            selection={selection}
            selections={selections}
            movingModels={movingModels}
            clearPendingMove={this.clearPendingMove}
            onSelectionChanged={this.changeSelection}
            width={mapWidth}
            height={mapHeight}
            // canvasSize={this.state.canvasSize}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            onZoom={this.zoom}
            onZoomToPoint={this.zoomToPoint}
            log={this.addLog}
            exitEditMap={() => { this.setState({ editMapMode: false })}}
          />
        </div>

        <SelectionDrawer
          connection={connection}
          selection={selection}
          game={game}
          extensions={extensions}
          log={this.addLog}
          actions={selectionActions}
          onCopyToken={this.onCopyToken.bind(this)}
          onEdit={() => { this.setState({ editOpen: true })}}
          onShowCards={() => { this.setState({ showCards: true })}}
          effects={moduleData.effects || []}
        />

        <AddWidgetDialog
          moduleData={moduleData}
          open={this.state.addWidgetOpen}
          onClose={() => { this.setState({ addWidgetOpen: false }) }}
          onAddWidget={this.onAddWidget.bind(this)}
        />

        {ConfiguredAddModelDialog && (
          <ConfiguredAddModelDialog
            open={this.state.addModelOpen}
            onClose={() => { this.setState({ addModelOpen: false }) }}
            onAddToken={this.addToken.bind(this)}
            color={this.state.selectedColor}
            onChangeColor={this.onChangeColor}
            selection={selection}
            game={game}
            connection={connection}
            moduleData={moduleData}
          />
        )}

        <AddShapeDialog
          connection={connection}
          game={game}
          extensions={extensions}
          open={this.state.addShapeOpen}
          mapHeight={game.attrs.height}
          mapWidth={game.attrs.width}
          onClose={() => { this.setState({ addShapeOpen: false }) }}
        />

        <ClockDialog
          game={game}
          open={this.state.clockOpen}
          log={this.addLog}
          onClose={() => { this.setState({ clockOpen: false }) }}
        />

        <CommandDialog
          open={this.state.commandDialogOpen}
          game={game}
          canvas={this.canvas}
          connection={this.state.connection}
          selection={selection}
          selections={selections}
          log={this.addLog}
          moduleData={moduleData}
          extensions={extensions}
          onClose={() => { this.setState({ commandDialogOpen: false }) }}
        />

        <EditDialog
          open={this.state.editOpen}
          resource1Label={game.attrs.resource1Label}
          resource2Label={game.attrs.resource2Label}
          resource3Label={game.attrs.resource3Label}
          onClose={() => { this.setState({ editOpen: false }) }}
          record={selection}
        />

        <InviteDialog
          game={this.state.game}
          open={this.state.inviteOpen}
          onClose={() => { this.setState({ inviteOpen: false }) }}
        />

        <KeyboardShortcutsDialog
          extensions={extensions}
          game={this.state.game}
          open={this.state.keyboardShortcutsOpen}
          onClose={() => this.setState({ keyboardShortcutsOpen: false })}
        />

        <LogDialog
          connection={this.state.connection}
          game={game}
          open={this.state.logOpen}
          onClose={() => { this.setState({ logOpen: false }) }}
        />

        <NotesDialog
          game={this.state.game}
          open={this.state.notesOpen}
          onClose={() => { this.setState({ notesOpen: false }) }}
        />

        <RollDialog
          open={this.state.rollOpen}
          extensions={extensions}
          onClose={() => { this.setState({ rollOpen: false }) }}
          onRoll={() => { this.setState({ rollOpen: true }) }}
          addLog={this.addLog}
          game={game}
          system={game.attrs.system}
          user={this.props.user}
        />

        <ConfirmAllButton
          onClick={this.confirmMoves}
          show={this.state.movingModels.length > 0}
        />

        <SaveMapDialog
          connection={connection}
          onClose={() => { this.setState({ saveMapOpen: false }) }}
          open={this.state.saveMapOpen}
        />

        <SearchDeckDialog
          game={game}
          connection={connection}
          selection={selection}
          log={this.addLog}
          onClose={() => { this.setState({ searchDeckOpen: false }) }}
          open={ this.state.searchDeckOpen }
        />

        <CardsDialog
          open={this.state.showCards}
          cards={selection && selection.attrs.cards}
          title={selection && selection.attrs.label}
          onClose={() => { this.setState({ showCards: false }) } }
        />

        <LogSnackbar connection={connection} />

        <SelectionTooltip
          actions={selectionActions}
          connection={connection}
          game={game}
          extensions={extensions}
          selection={selection}
          log={this.addLog}
          onRoll={() => { this.setState({ rollOpen: true })}}
          effects={moduleData.effects || []}
        />

        {Object.entries(extensions.modals || {}).map(([key, Modal]) => (
          <Modal
            key={key}
            game={game}
            connection={connection}
            selection={selection}
            log={this.addLog}
            onClose={this.onModalClose}
            open={ this.state.modal === key }
          />
        ))}
      </div>
    );
  }
}

export default withWidth()(Game);
