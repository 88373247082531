import { fabric } from 'fabric-with-gestures';
import FabricComponent from '../../../map/FabricComponent';
import { LAYER_WIDGETS } from '../../../map/Layers';

const deckWidth = 4;
const deckHeight = 6.5;
const deckPadding = 0.25;

export default class Deck extends FabricComponent {
  createFabricObject() {
    this.loadImage();
    return new fabric.Object({ layer: LAYER_WIDGETS });
  }
  loadImage() {
    const {cards = [], faceUp} = this.props.record.attrs;
    const topCard = cards?.[0];

    if (!topCard) {
      this.renderDeck();
    } else {
      const url = faceUp ? topCard.frontImageUrl : topCard.backImageUrl;

      fabric.Image.fromURL(url, img => {
        const scale = (deckWidth - 2 * deckPadding) * this.props.pixelsPerInch / img.width;
        img.set({
          scaleX: scale,
          scaleY: scale,
          originY: "bottom",
          left: deckPadding * this.props.pixelsPerInch,
          top: (deckHeight - deckPadding) * this.props.pixelsPerInch,
        })
        this.renderDeck(img);
      });
    }
  }
  renderDeck(img) {
    const { cards = [], color, name } = this.props.record.attrs;

    this.props.canvas.remove(this.fabricObject);

    const box = new fabric.Rect({
      width: deckWidth * this.props.pixelsPerInch,
      height: deckHeight * this.props.pixelsPerInch,
      strokeWidth: 0,
      fill: color,
    });

    const label = new fabric.Text(name, {
      fill: "#ffffff",
      fontSize: 0.4 * this.props.pixelsPerInch,
      left: deckPadding * this.props.pixelsPerInch,
      top: deckPadding * this.props.pixelsPerInch,
      fontFamily: "Roboto",
    });

    const subLabel = new fabric.Text(`Deck: ${cards.length}`, {
      fill: "#ffffff",
      fontSize: 0.3 * this.props.pixelsPerInch,
      left:  deckPadding * this.props.pixelsPerInch,
      top:  (deckPadding + 0.5) * this.props.pixelsPerInch,
      fontFamily: "Roboto",
    });

    const elements = [box, label, subLabel];
    if (img) elements.push(img);

    const group = new fabric.Group(elements, {
      layer: LAYER_WIDGETS,
      hasControls: false
    });
    this.fabricObject = group;
    group.set(this.fabricObjectAttrs(this.props.record.attrs, this.props.pixelsPerInch));

    this.props.canvas.add(group);
  }
  componentDidUpdate(prevProps) {
    const { faceUp, cards } = this.props.record.attrs;
    const prev = prevProps.record.attrs;
    if (
      faceUp !== prev.faceUp ||
      JSON.stringify(cards) !== JSON.stringify(prev.cards)
    ) {
      this.loadImage();
    }
    this.updateFabricObject();
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    return {
      originX: 'center',
      originY: 'center',
      left: attrs.x * pixelsPerInch,
      top: attrs.y * pixelsPerInch,
      record: this.props.record,
    };
  }
}
