import { fabric } from 'fabric-with-gestures';

import FabricComponent from './FabricComponent';
import { LAYER_AURAS } from './Layers';

export default class Aura extends FabricComponent {
  createFabricObject() {
    if (this.props.shape === "rect") {
      return new fabric.Rect({
        width: 0,
        height: 0,
        strokeWidth: 1,
        stroke: '#000000',
        fill: '#000000',
        originX: 'center',
        originY: 'center',
        evented: false,
        selectable: false,
        opacity: 0.1,
        layer: LAYER_AURAS,
      });
    }

    return new fabric.Circle({
      radius: 0,
      strokeWidth: 1,
      stroke: '#000000',
      fill: '#000000',
      originX: 'center',
      originY: 'center',
      evented: false,
      selectable: false,
      opacity: 0.1,
      layer: LAYER_AURAS,
    });
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    const { isSelected } = this.props;
    const { auraColor, auraRadius, width, height, x, y, shape, facing } = attrs;
    if (!auraRadius) return;

    let fill = auraColor;
    if ((!fill || fill === '' || fill === 'transparent') && isSelected) {
      fill = 'rgba(255, 255, 255, 0.3)';
    }
    if (shape === "rect") {
      return {
        left: x * pixelsPerInch,
        top: y * pixelsPerInch,
        width: Math.max(0, (2 * auraRadius + width) * pixelsPerInch - 0.5),
        height: Math.max(0, (2 * auraRadius + height) * pixelsPerInch - 0.5),
        rx: auraRadius * pixelsPerInch,
        ry: auraRadius * pixelsPerInch,
        fill,
        angle: facing,
        opacity: isSelected ? 0.3 : 0.15,
        stroke: isSelected ?  '#ffffff' : '#000000',
      };
    }

    const radius = Math.max(0, (auraRadius + width / 2) * pixelsPerInch - 0.5);
    return {
      left: x * pixelsPerInch,
      top: y * pixelsPerInch,
      radius,
      fill,
      opacity: isSelected ? 0.3 : 0.15,
      stroke: isSelected ?  '#ffffff' : '#000000',
    };
  }
}
