import React, { Component, Fragment } from 'react';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import settings from "../data/settings";

import ListDetailDialog from '../../../ListDetailDialog';
import ColorPicker from '../../../game/ColorPicker';
import modelToToken from '../../../game/modelToToken';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { ThirtyFpsSelect } from '@mui/icons-material';

export default class AddModelDialog extends Component {
  state = {
    searchText: '',
    selectedDeck: "none",
  }
  modelItem(model, indent: false) {
    return (
      <Fragment key={`${model.name} ${model.factions[0]}`}>
        <ListItem dense button onClick={() => this.addModel(model)}>
          <ListItemAvatar style={{ marginLeft: (indent ? 16 : 0) }}>
            {model.avatar ? <Avatar src={model.avatar}/> : <Avatar style={{ color: '#ffffff', backgroundColor: this.props.color }}>{model.name[0]}</Avatar>}
          </ListItemAvatar>
          <ListItemText>{model.alias || model.name}</ListItemText>
        </ListItem>
        {(model.includes || []).map(model => this.modelItem(model, { indent: true }))}
      </Fragment>
    );
  }
  addModel(model) {
    const { selection } = this.props;
    let x = selection ? selection.attrs.x : 6 + Math.random() * 12;
    let y = selection ? selection.attrs.y : 12;
    const attrs = Object.assign({}, modelToToken(model), {
      x,
      y,
      facing: 0,
      color: this.props.color
    });
    this.props.game.update({ deploy: true });
    this.props.onAddToken(attrs);

    if(model.commandCard && this.state.selectedDeck !== "none") {
      this.props.connection.listDecks().then(decks => {
        const deck = decks.find((d) => d.attrs.name === this.state.selectedDeck);
        if (deck) {
          const cards = [...deck.attrs.cards || [], {...model.commandCard, label: model.name}];
          deck.update({ cards });
        }
      });
    }
  }
  modelList(faction) {
    const models = this.props.moduleData.models.filter(model => model.factions.includes(faction));
    const categories = Array.from(new Set(models.map(model => model.type))).sort();
    return categories.map((category, i) => (
      <Fragment key={`category-${i}`}>
        <ListSubheader style={{ backgroundColor: '#fff' }}>{category}</ListSubheader>
        {models.filter(model => model.type === category).map(model => this.modelItem(model))}
      </Fragment>
    ))
  }
  deckSelection = () => {
    return (
      <FormControl margin='normal' >
        <FormLabel id="deck-selection">Add Command Card to:</FormLabel>
        <RadioGroup
          aria-labelledby="deck-selection"
          name="deck-selection"
          value={this.state.selectedDeck}
          onChange={(e) => this.setState({selectedDeck: e.target.value})}
          row
        >
          <FormControlLabel value={settings.gameSettings.player1StackLabel} control={<Radio />} label={settings.gameSettings.player1StackLabel} />
          <FormControlLabel value={settings.gameSettings.player2StackLabel} control={<Radio />} label={settings.gameSettings.player2StackLabel} />
          <FormControlLabel value="none" control={<Radio />} label="None" />
        </RadioGroup>
      </FormControl>
    );
  }
  factionDetail = (faction) => {
    return (
      <Fragment>
        <ColorPicker
          label="Base Color"
          value={this.props.color}
          onChange={(e) => this.props.onChangeColor(e.target.value)}
        />
        {this.deckSelection()}
        <List>
          {this.modelList(faction)}
        </List>
      </Fragment>
    )
  }
  filteredModels() {
    const { searchText } = this.state;
    let searchTextLower = searchText.toLowerCase();
    const allModels = [];
    this.props.moduleData.models.forEach(model => {
      allModels.push(model);
      (model.includes || []).forEach(included => allModels.push(included))
    });
    const matches = allModels.filter(model => {
      return model.name.toLowerCase().indexOf(searchTextLower) >= 0 || (model.alias || '').toLowerCase().indexOf(searchTextLower) >= 0
    });
    return matches.slice(0, 25);
  }
  searchDetail = () => {
    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="search"
              label="Search Text"
              value={this.state.searchText}
              onChange={e => this.setState({ searchText: e.target.value })}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ColorPicker
              label="Base Color"
              value={this.props.color}
              onChange={(e) => this.props.onChangeColor(e.target.value)}
            />
          </Grid>
        </Grid>
        {this.deckSelection()}
        <List>
          {this.filteredModels().map(model => this.modelItem(model))}
        </List>
      </Fragment>
    )
  }
  render() {
    const { onClose, open } = this.props;
    if (!open) return null;

    return (
      <ListDetailDialog
        onClose={onClose}
        title="Add Model"
        selectFirst
        itemCategories={{ "Quick Add": ['Search'], "Factions": [...new Set(this.props.moduleData.models.map(model => model.factions).flat().sort())] }}
        detailTitle={(faction) => faction}
        renderItem={(faction) => (
          <Fragment>
            <ListItemText primary={faction} />
          </Fragment>
        )}
        renderDetail={(faction) => {
          if (faction === 'Search') {
            return this.searchDetail();
          }
          return this.factionDetail(faction);
        }}
      />
    )
  }
}
