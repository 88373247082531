import { fabric } from 'fabric-with-gestures';

import FabricComponent from './FabricComponent';

import { dPoints, roundDistance } from '../Distance';
import { LAYER_UI } from './Layers';

export default class DistanceLabel extends FabricComponent {
  static defaultProps = {
    bringToFront: true,
  }
  createFabricObject() {
    return new fabric.Text('', {
      stroke: '#fff',
      fill: '#fff',
      fontSize: 0.6 * this.props.pixelsPerInch,
      fontFamily: 'Roboto',
      originX: 'center',
      originY: 'center',
      evented: false,
      selectable: false,
      opacity: 0,
      layer: LAYER_UI,
    });
  }
  sumDistance(points) {
    let lastPoint = null;
    let sum = 0;
    points.forEach((point) => {
      if (lastPoint) {
        let distance = dPoints(point, lastPoint);
        if (point.doubleCost) distance = distance * 2;
        sum = sum + distance;
      }
      lastPoint = point;
    });
    return roundDistance(sum);
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    const { moves } = this.props;
    if (!moves || !moves.length) return { opacity: 0 };

    const distance = this.sumDistance(moves);
    const distanceText = `${distance}”`;
    const lastMove = moves[moves.length - 1];
    return {
      left: pixelsPerInch * lastMove.x,
      top: pixelsPerInch * (lastMove.y - attrs.width / 2) - 15,
      text: distanceText,
      opacity: 1
    }
  }
}
