import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BackspaceIcon from '@mui/icons-material/Backspace';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';

import Dialog from '../../../WTDialog';

import withWidth, { isWidthUp } from '../../../withWidth';
import { Box, CardActions, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

class EditDeckDialog extends Component {
  state = {
    selectedTab: 1,
    selected: [],
    cards: this.props.selection?.attrs?.cards || [],
    discard: this.props.selection?.attrs?.discard || [],
  }
  componentDidUpdate(prevProps) {
    const { selection } = this.props;
    if ( selection && selection !== prevProps.selection) {
      this.setState({cards: this.props.selection.attrs.cards || []});
      this.setState({discard: this.props.selection.attrs.discard || []});
    }
  }
  log(text){
    if (!this.props.log) return
    this.props.log(text, this.props.selection.attrs.name);
  }
  select(i) {
    const { selected } = this.state;
    if (selected.includes(i)) {
      const newValue = [...selected];
      newValue.splice(selected.indexOf(i), 1);
      this.setState({ selected: newValue });
    } else {
      this.setState({ selected: [...selected, i] });
    }
  }
  onMoveCardBack = (i) => {
    if (i < 1) return;

    const cards = [...this.state.cards];
    const tmp = cards[i];
    cards[i] = cards[i - 1];
    cards[i - 1] = tmp;

    this.setState({cards});
    this.props.log("Re-arranged cards");
  }
  onMoveCardForward = (i) => {
    if (i > this.state.cards.length - 2) return;

    const cards = [...this.state.cards];
    const tmp = cards[i];
    cards[i] = cards[i + 1];
    cards[i + 1] = tmp;

    this.setState({cards});
    this.log("Re-arranged cards");
  }
  onDiscardCard = (i) => {
    const cards = [...this.state.cards.slice(0, i), ...this.state.cards.slice(i + 1)];
    const discard = [...this.state.discard, this.state.cards[i]];
    this.setState({cards, discard});
    this.log("Moved card to Discard Pile");
  }
  onRestoreCard = (i) => {
    const discard = [...this.state.discard.slice(0, i), ...this.state.discard.slice(i + 1)];
    const cards = [...this.state.cards, this.state.discard[i]];
    this.setState({cards, discard});
    this.log("Moved card from Discard Pile to Deck");
  }
  onRemoveCard = (i) => {
    const discard = [...this.state.discard.slice(0, i), ...this.state.discard.slice(i + 1)];
    this.setState({discard});
  }
  deckCard = (card, i, cards) => {
    const { selected } = this.state;

    return (
      <Card key={`${card.label}-${i}`} elevation={8} style={{ marginRight: 16, marginBottom: 16, width: 250, borderWidth: 5, borderStyle: 'solid', borderColor: (selected.includes(i) ? '#4caf50' : 'transparent') }}>
        <CardActionArea>
          {card.frontImageUrl && <CardMedia style={{ height: 350 }} title={card.label} image={card.frontImageUrl} />}
          {!card.frontImageUrl && <Typography>{card.label || "Unknown"}</Typography>}
        </CardActionArea>
        <CardActions disableSpacing style={{justifyContent: "space-between"}}>
          <IconButton color="primary" onClick={this.onMoveCardBack.bind(null, i)} disabled={i === 0} disableTouchRipple>
            <ArrowBackIcon />
          </IconButton>
          <Box>
            <IconButton color="primary" onClick={this.onDiscardCard.bind(null, i)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <IconButton color="primary" onClick={this.onMoveCardForward.bind(null, i)} disabled={i === cards.length - 1} disableTouchRipple>
            <ArrowForwardIcon />
          </IconButton>
        </CardActions>
      </Card>
    )
  }
  discardCard = (card, i, cards) => {
    const { selected } = this.state;

    return (
      <Card key={`${card.label}-${i}`} elevation={8} style={{ marginRight: 16, marginBottom: 16, width: 250, borderWidth: 5, borderStyle: 'solid', borderColor: (selected.includes(i) ? '#4caf50' : 'transparent') }}>
        <CardActionArea>
          {card.frontImageUrl && <CardMedia style={{ height: 350 }} title={card.label} image={card.frontImageUrl} />}
          {!card.frontImageUrl && <Typography>{card.label || "Unknown"}</Typography>}
        </CardActionArea>
        <CardActions disableSpacing style={{justifyContent: "center"}}>
          <IconButton color="primary" onClick={this.onRestoreCard.bind(null, i)}>
            <UndoIcon />
          </IconButton>
          <IconButton color="primary" onClick={this.onRemoveCard.bind(null, i)}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    )
  }
  onTabSelectionChange = (e, value) => {
    this.setState({selectedTab: value});
  }
  content() {
    return (
      <>
        <Tabs value={this.state.selectedTab} onChange={this.onTabSelectionChange}>
          <Tab label={`Deck (${this.state.cards.length})`} value={1} />
          <Tab label={`Discard Pile (${this.state.discard.length})`} value={2} />
        </Tabs>
        <Box display="flex" flexWrap="wrap">
          {this.state.selectedTab === 1 ? this.state.cards.map(this.deckCard) : this.state.discard.map(this.discardCard)}
        </Box>
      </>
    );
  }
  close = () => {
    this.setState({ selected: [], selectedTab: 1 });
    this.props.selection.update({cards: this.state.cards, discard: this.state.discard});
    this.props.onClose();
  }
  render() {
    const { open, selection } = this.props;
    if (!open || !selection) return null;

    const { name } = selection.attrs;

    return (
      <Dialog open fullScreen onClose={this.close}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>{this.content()}</DialogContent>
        <DialogActions>
          <Button onClick={this.close}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withWidth()(EditDeckDialog);