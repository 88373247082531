// Calculates the distance between two points.
export function dPoints(point1, point2) {
  return Math.pow(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2), 0.5);
}

// Calculates the distance between the perimeters of two circles.
export function dCircles(c1, c2) {
  const distance = dPoints(c1, c2);
  return distance - c1.radius - c2.radius;
}

//  Calculates the vertice coordinates of a potentially rotated rectangle.
//  +------------------+
//  |                  |
//  |             dw   |
//  |         + ------->
//  |         |dh      |
//  |         |        |
//  +---------v--------+
function calculateVertices(r) {
  const { x, y, width, height, facing } = r;
  const angle = facing * Math.PI / 180;

  // Rotated dw and dh
  const dhx = -height / 2 * Math.sin(angle);
  const dhy = height / 2 * Math.cos(angle);
  const dwx = width / 2 * Math.cos(angle);
  const dwy = width / 2 * Math.sin(angle);

  return [
    {x: x + dhx - dwx, y: y + dhy - dwy},
    {x: x + dhx + dwx, y: y + dhy + dwy},
    {x: x - dhx + dwx, y: y - dhy + dwy},
    {x: x - dhx - dwx, y: y - dhy - dwy},
  ];
}

// Calculates the distance between a point P and a linear Segment AB. There are
// three scenarios: (a) P lies between A, B, (b) P lies to the left of A, (c) P
// lies to the right of B.
//
//      +P
//
// A---------------------B
function dPointSegment(p, a, b) {
  const ABx = b.x - a.x;
  const ABy = b.y - a.y;
  const BPx = p.x - b.x;
  const BPy = p.y - b.y;
  const APx = p.x - a.x;
  const APy = p.y - a.y;

  const ABdotBP = ABx * BPx + ABy * BPy;
  const ABdotAP = ABx * APx + ABy * APy;

  const dAB = dPoints(a, b);
  const dAP = dPoints(p, a);
  const dBP = dPoints(p, b);

  // P lies to the right of AB.
  if (ABdotBP > 0) return dBP;
  // P lies to the left of AB.
  if (ABdotAP < 0) return dAP;

  // Else calculates the height of the triangle APB.

  // Half perimeter
  const hp = (dAB + dAP + dBP) / 2;
  // Heron's formula
  const area = Math.sqrt(hp * (hp - dAB) * (hp - dAP) * (hp - dBP));
  return 2 * area / dAB;
}

// Calculates the distance between two rects R1 and R2. There are three
// possible scenarios for the value of the distance: (a) it is the
// distance between two vertices of the rectangles, (b) it is the dtstance
// between a vertice of R1 and a side of R2, (c) the iverse of b. All possible
// values are calculated and the minimum is returned.
export function dRects(r1, r2) {
  const vertices1 = calculateVertices(r1);
  const vertices2 = calculateVertices(r2);

  let min = Number.MAX_SAFE_INTEGER;

  // Scenario a.
  for (const v1 of vertices1) {
    for (const v2 of vertices2) {
      const d = dPoints(v1, v2);
      if (d < min) min = d;
    }
  }

  // Scenario b.
  for (const v1 of vertices1) {
    for (let i = 0; i < 4; ++i) {
      const j = i >= 3 ? 0 : i + 1;
      const d = dPointSegment(v1, vertices2[i], vertices2[j]);
      if (d < min) min = d;
    }
  }

  // Scenario c.
  for (const v2 of vertices2) {
    for (let i = 0; i < 4; ++i) {
      const j = i >= 3 ? 0 : i + 1;
      const d = dPointSegment(v2, vertices1[i], vertices1[j]);
      if (d < min) min = d;
    }
  }

  return min;
}

// Calculates the distance from the perimeter of a circle to the closest
// point of a rectangle. Follows the same principle as dRects but only needs
// to check one point (the center) from the circle.
export function dCircleRect(c, r) {
  const vertices = calculateVertices(r);

  let min = Number.MAX_SAFE_INTEGER;

  for (const v of vertices) {
    const d = dPoints({ x: c.x, y: c.y }, v);
    if (d < min) min = d;
  }

  for (let i = 0; i < 4; ++i) {
    const j = i >= 3 ? 0 : i + 1;
    const d = dPointSegment({ x: c.x, y: c.y }, vertices[i], vertices[j]);
    if (d < min) min = d;
  }

  return min - c.radius;
}

export function roundDistance(distance) {
  let rounded = Math.ceil(distance * 10) / 10;
  if (rounded - distance > 0.0999999) {
    // if a model moved exactly 1" don't let floating point math errors show it as 1.1"
    return Math.floor(distance * 10) / 10;
  }
  return rounded;
}
