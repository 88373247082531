import React, { Component, Fragment } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import withWidth, { isWidthUp } from '../withWidth';

import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CasinoIcon from '@mui/icons-material/Casino';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import PlaceIcon from '@mui/icons-material/Place';
import RemoveIcon from '@mui/icons-material/Remove';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import CardsIcon from './CardsIcon';
import { widthFor } from '../map/Token';

class AttackIcon extends Component {
  render() {
    return <SvgIcon>
      <path d="M6.92,5H5L14,14L15,13.06M19.96,19.12L19.12,19.96C18.73,20.35 18.1,20.35 17.71,19.96L14.59,16.84L11.91,19.5L10.5,18.09L11.92,16.67L3,7.75V3H7.75L16.67,11.92L18.09,10.5L19.5,11.91L16.83,14.58L19.95,17.7C20.35,18.1 20.35,18.73 19.96,19.12Z" />
    </SvgIcon>
  }
}

class SelectionActions extends Component {
  stopPropagation(callback) {
    return function(e) {
      e.stopPropagation();
      callback(e);
    }
  }
  addToCounter = (amount) => {
    const { selection } = this.props;
    const { value } = selection.attrs;
    const newValue = Math.max(value + amount, 0);
    if (amount > 0) {
      this.props.log(`${selection.attrs.label} counter increased to ${newValue}`);
    } else {
      this.props.log(`${selection.attrs.label} counter decreased to ${newValue}`);
    }
    selection.update({ value: newValue });
  }
  kickScatter = () => {
    const { selection } = this.props;
    const directionRoll = Math.floor(Math.random() * 6) + 1;
    const distance = Math.floor(Math.random() * 6) + 1;
    this.props.log(`${distance}” to the ${directionRoll}`);
    const facingRadians = Math.PI * selection.attrs.facing / 180;
    let direction;
    if (directionRoll < 4) {
      direction = 10 + 20 * directionRoll;
    } else {
      direction = 30 + 20 * directionRoll;
    }
    const directionRollRadians = Math.PI * direction / 180;
    const directionRadians = directionRollRadians + facingRadians;
    const totalDistance = distance + widthFor('30mm');
    const x = selection.attrs.x + Math.cos(directionRadians) * totalDistance;
    const y = selection.attrs.y + Math.sin(directionRadians) * totalDistance;
    this.props.connection.add('token', {
      label: 'Ball',
      imageUrl: '/GuildBall/avatars/ball.jpg',
      x,
      y,
      facing: 0,
      width: widthFor('30mm'),
      deletable: true
    });
  }
  standardScatter = () => {
    const { selection } = this.props;
    const directionRoll = Math.floor(Math.random() * 6) + 1;
    const distance = Math.floor(Math.random() * 6) + 1;
    this.props.log(`${distance}” to the ${directionRoll}`);
    const facingRadians = Math.PI * (selection.attrs.facing + 30) / 180;
    const directionRollRadians = Math.PI * (directionRoll + 3) / 3;
    const directionRadians = directionRollRadians + facingRadians;
    const totalDistance = distance + widthFor('30mm');
    const x = selection.attrs.x + Math.cos(directionRadians) * totalDistance;
    const y = selection.attrs.y + Math.sin(directionRadians) * totalDistance;
    this.props.connection.add('token', {
      label: 'Ball',
      imageUrl: '/GuildBall/avatars/ball.jpg',
      x,
      y,
      facing: 0,
      width: widthFor('30mm'),
      deletable: true
    });
  }
  deviation(widgetType) {
    const { selection } = this.props;
    const directionRoll = Math.floor(Math.random() * 6) + 1;
    const distanceRoll = Math.floor(Math.random() * 6) + 1;
    let distance = distanceRoll;
    const maxDistance = window.prompt('Max distance? Empty for no maximum.');
    if (maxDistance && maxDistance.length > 0 && parseFloat(maxDistance, 10) < distanceRoll) {
      this.props.log(`${distanceRoll}” to the ${directionRoll}. Limited to max of ${maxDistance}”`);
      distance = parseFloat(maxDistance, 10);
    } else {
      this.props.log(`${distanceRoll}” to the ${directionRoll}`);
    }
    const directionRollRadians = Math.PI * directionRoll / 3;
    const facingRadians = Math.PI * (selection.attrs.facing + 30) / 180;
    const directionRadians = directionRollRadians + facingRadians;
    const x = selection.attrs.x + Math.cos(directionRadians) * distance;
    const y = selection.attrs.y + Math.sin(directionRadians) * distance;
    this.props.onAddWidget(widgetType, { fill: '#9e9e9e', label: '', immobile: true, x, y });
  }
  actions() {
    const { game, selection, system, extensions } = this.props;
    if (!selection) {
      return [];
    }
    const { widgetType } = selection.attrs;
    const isToken = selection && selection.type === 'token';
    const actions = [];
    if (isToken) {
      if (selection.attrs.cards) {
        actions.push({
          icon: <CardsIcon />,
          label: 'View Cards',
          onClick: this.props.onShowCards,
        });
      }
      if (game.attrs.doubleMoveCost) {
        actions.push({
          icon: <NaturePeopleIcon />,
          label: '2x Move Cost',
          selected: selection.attrs.doubleMoveCost,
          onClick: () => { selection.update({ doubleMoveCost: !selection.attrs.doubleMoveCost })},
        });
      }
      actions.push({
        icon: <EditIcon />,
        label: 'Edit Model',
        onClick: this.props.onEdit,
      });
      actions.push({
        icon: <LibraryAddIcon />,
        label: 'Copy Model',
        onClick: this.props.onCopyToken,
      });
    }
    const isRingTemplate = widgetType && widgetType.indexOf('RingTemplate') >= 0;
    if (isRingTemplate || widgetType === 'Token' || widgetType === 'WallTemplate') {
      if (isRingTemplate || widgetType === 'WallTemplate') {
        actions.push({
          icon: <PlaceIcon color={selection.attrs.immobile ? 'error' : ''} />,
          label: selection.attrs.immobile ? 'Unpin' : 'Pin',
          onClick: () => {
            selection.update({ immobile: !selection.attrs.immobile })
          },
          selected: selection.attrs.immobile,
        });
      }
      actions.push({
        icon: <LibraryAddIcon />,
        label: 'Copy',
        onClick: () => {
          let width = selection.attrs.width || 0;
          if (widgetType === 'RingTemplate3') {
            width = 3;
          } else if (widgetType === 'RingTemplate4') {
            width = 4;
          } else if (widgetType === 'RingTemplate5') {
            width = 5;
          }
          this.props.onAddWidget(
            selection.attrs.widgetType,
            {
              ...selection.attrs,
              x: selection.attrs.x + width
            }
          )
        },
      });
    }
    if (selection.attrs.widgetType === 'DeviationTemplate') {
      actions.push({
        icon: <Filter3Icon />,
        label: 'Deviate: 3” AoE',
        onClick: () => { this.deviation('RingTemplate3') },
      });
      actions.push({
        icon: <Filter4Icon />,
        label: 'Deviate: 4” AoE',
        onClick: () => { this.deviation('RingTemplate4') },
      });
      actions.push({
        icon: <Filter5Icon />,
        label: 'Deviate: 5” AoE',
        onClick: () => { this.deviation('RingTemplate5') },
      });
    }
    if (selection.attrs.widgetType === 'Counter') {
      actions.push({
        icon: <AddIcon />,
        label: 'Increase',
        onClick: () => { this.addToCounter(1) },
      });
      actions.push({
        icon: <RemoveIcon />,
        label: 'Decrease',
        onClick: () => { this.addToCounter(-1) },
      });
    }
    if (selection.attrs.widgetType === 'KickScatter') {
      actions.push({
        icon: <CasinoIcon />,
        label: 'Scatter',
        onClick: this.kickScatter,
      });
    }
    if (selection.attrs.widgetType === 'StandardScatter') {
      actions.push({
        icon: <CasinoIcon />,
        label: 'Scatter',
        onClick: this.standardScatter,
      });
    }
    if (selection.attrs.deletable) {
      actions.push({
        icon: <DeleteIcon />,
        label: 'Remove',
        onClick: this.props.onRemove,
      })
    }
    if (selection.type === 'deck') {
      if (extensions.deckActions) return extensions.deckActions(
        selection,
        this.props.log,
        this.props.onModalOpen
      );

      actions.push({
        icon: <ZoomInIcon />,
        label: 'Search',
        onClick: this.props.onSearchDeck,
      });
      actions.push({
        icon: <ThreeSixtyIcon />,
        label: 'Shuffle',
        onClick: this.props.onShuffle,
      });
      actions.push({
        icon: <CardsIcon />,
        label: 'Draw',
        onClick: this.props.onDraw,
      });
      actions.push({
        icon: <Rotate90DegreesCcwIcon />,
        label: 'Flip',
        onClick: () => selection.update({ faceUp: !selection.attrs.faceUp }),
      });
      if (selection.attrs.cards.length === 1 && selection.attrs.cards[0].tokens) {
        actions.push({
          icon: <PlaceIcon />,
          label: 'Set Up',
          onClick: () => {
            selection.attrs.cards[0].tokens.forEach(token => {
              this.props.connection.add('token', token);
            });
          },
        });
      }
    }
    return actions;
  }
  render() {
    if (isWidthUp('md', this.props.width)) {
      return <List style={{ padding: 0 }}>
        {this.actions().map((action, i) => {
          return <ListItem key={i} selected={action.selected} disablePadding>
            <ListItemButton onClick={action.onClick} disabled={action.disabled}>
              <ListItemIcon>{action.icon}</ListItemIcon>
              <ListItemText primary={action.label} />
            </ListItemButton>
          </ListItem>
        })}
      </List>
    }
    return <Fragment>
      {this.actions().map((action, i) => {
        return (
          <IconButton
            key={i}
            style={{ marginLeft: 'auto', backgroundColor: action.selected ? 'rgba(0, 0, 0, 0.14)' : null }}
            onClick={this.stopPropagation(action.onClick)}
          >
            {action.icon}
          </IconButton>
        )
      })}
    </Fragment>
  }
}

export default withWidth()(SelectionActions);
